import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import { Accordion } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import classes from '../../css/item.module.css';


const SectionAccordion = (props) => {
    const groceries = [
        {
            value: 'What is unique about Pluri?',
            description:
                'Pluri specializes in allowing people to send data alongside transactions, or allowances. Another feature Pluri brings to the table is the ability to consolidate multiple transfers within a single transaction, which of course can also be attached with data. This allows for many new creative solutions that can provide new utilities to freshen the blockchain landscape.'
        },
        {
            value: 'Why should I use Pluri in my Projects?',
            description:
                `Pluri can provide security, transparency, freedom of data, cost savings, single click purchases (No longer need to give allowance to contract) and new creative ideas. For example you can utilize Pluri to process multiple transfers based on a percentage to users. Another example is imagine a user purchases an item from your dapp store, instead of utilizing a costly smart contract, you can instead send the item id alongside the transaction. This allows the user to receive the item without the need of a smart contract, but from verification of your backend reader (a server that listens to blockchain transactions, and indexes it in a local database).`,
        },
        {
            value: 'Where is all of the data hosted?',
            description:
                "When a transaction is made with Pluri, the transaction's metadata is simultaneously transferred and stored on the blockchain, utilizing event logs for data storage. The data size must remain within the block's gas limit.",
        },
        {
            value: 'Who governs Pluri?',
            description: "Pluri has adopted a multi-signature wallet for secure governance, requiring consensus to prevent any single party's dominance. The multi-sig will not have minting rights, but will have the authority to replace the staking system incase of software bugs. The stakeholder and faucet contract are orphaned to prevent alterations to the tokenomic rule set midway. The goal is an autonomous, resilient, and self-sustaining system, minimizing external influences on its stability and to build community trust, while maintaining the ability to meet upgrade requirements in the future."
        },
        {
            value: 'How can I stay up date on Pluri?',
            description: "To stay up to date with Pluri, new posts will be found on the website in the newsletter section, and important updates will be displayed via the website banner."
        }
    ];

    const items = groceries.map((item) => (
        <Accordion.Item key={item.value} value={item.value} >
            <Accordion.Control >{item.value}</Accordion.Control>
            <Accordion.Panel><p style={{ padding: '0px 13px 13px 13px', maxWidth: '1000px' }}>{item.description}</p></Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <>



            <div className='accord-container'>
                <div style={{ marginBottom: '50px' }} className='home-text-wrapper'>
                    <h1>
                        What is <span>Pluri?</span>
                    </h1>
                    <div className='hero-center'>
                        <p style={{ maxWidth: '477px', color: 'white' }}>Independent commerce, independent communication (icic), Pluri allows data transfer alongside monetary transactions.</p>
                    </div>




                </div>
                <Accordion classNames={{ chevron: classes.chevron }}
                    chevron={<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} className={classes.icon}><i style={{ fontSize: '35px', color: 'var(--main-color)' }} className='material-icons-rounded'>add</i></div>} variant="contained" defaultValue="Apples">
                    {items}

                </Accordion>
            </div>

        </>
    );
};

export default SectionAccordion;
