import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import Navbar from '../components/navbar';
import { useNavigate } from 'react-router-dom';
// import DashBackPlate from '../assets/dashBackPlate.svg'
import { Helmet } from 'react-helmet';
import SectionBulb from '../components/home/sectionBulb';
import Tilt from 'react-parallax-tilt';
import SectionText from '../components/home/sectionText';
import SectionCards from '../components/home/sectionCards';
import NewsletterCell from '../components/newsletterCell';

import SectionAccordion from '../components/home/sectionAccordion';
import { MetaTagHandler } from '../../library/client/serverCallHandler';
import { Link } from '../../node_modules/react-router-dom/dist/index';
import Footer from '../components/footer';
import U_CaseView from '../mainapp/usecases/u_caseview';
import { startLoading, stopLoading } from '../store/schemas/loadingSlice';

import { useDispatch, useSelector } from 'react-redux';


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init(); // Initialize AOS library

    return () => {
      AOS.refresh(); // Refresh AOS library on component unmount
    };
  }, []);

  const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);

  // const handleAnimate = () => {
  //   let ldContainer = document.getElementById('ld-container');
  //   ldContainer.classList.add('vivify', 'duration-300', 'fadeOut');
  //   document.getElementById('root').classList.add('back-img')
  //   document.getElementById('root').style.backgroundImage = `url(${DashBackPlate})`;
  //   document.body.style.backgroundColor = '#f5f5f5';
  //   document.getElementById('navbar').classList.add('vivify', 'duration-300', 'fadeOut');

  // }

  return (
    <>

      <MetaTagHandler>
        {() => {
          console.log("HIIIII")
          return (
            <>
              <title>PLURI</title>
              <meta name="description" content="PLURI Coin offers a way to connect data directly with transactional info on the blockchain. It provides benefits to developers by offloading database costs and simplifying payments, to investors by offering opportunities for staking and coin generation, and to ordinary users by ensuring data protection and freedom. Discover more with us." />
              <meta property="og:title" content="PLURI" />
              <meta property="og:description" content="PLURI Coin offers a way to connect data directly with transactional info on the blockchain. It provides benefits to developers by offloading database costs and simplifying payments, to investors by offering opportunities for staking and coin generation, and to ordinary users by ensuring data protection and freedom. Discover more with us." />
              <meta property="og:image" content={`https://${process.env.REACT_APP_ORIGIN}/assets/pluriBanner.png`} />
              <meta property="og:url" content={`https://${process.env.REACT_APP_ORIGIN}/`} />
              <meta property="og:site_name" content="PLURI" />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content="en_US" />
            </>
          )
        }}

      </MetaTagHandler>

      <div className="landing-container vivify" id='ld-container' >

        <div className='hero vivify fadeIn duration-500 delay-500' >

          <div className='hero-center'>

            <h1><span>Transfer</span> Crypto With Built-In <span>Metadata</span></h1>
            <p>Unleashing Blockchain's Potential for Secure, Decentralized Data Transfer and Storage.</p>


            <div className='on-button'>

              <div className='on-button-group'>


                <div> <Link to={'/polygon/demo/post/0xbc35a6c776b6eeb73592bc1e5789f30f9ad5252eb68cfeee63c17a369e9b9c84/a'} replace={true}>
                  <button className='hero-button' >
                    Read The Docs
                  </button>
                </Link></div>



                <div> <Link to={
                  '/' +
                  currentSelectedChain.slug + '/trydemo'

                } replace={true}>
                  <button className='button'><span>
                    Try Demo
                  </span></button>
                </Link></div>


              </div>

            </div>


            {/*     <div className='poweredBy'>
              <img src="assets/metamask.png" alt='metamask' />
              <p>Powered by Metamask</p>

            </div> */}
          </div>


        </div>

        {/*     <SectionBulb /> */}

        <SectionAccordion />


        <SectionCards />

        <U_CaseView />





      </div>

      <Footer />


      <style dangerouslySetInnerHTML={{
        __html: `
          .landing-container {
            display: flex;
            flex-direction: column;
          }

          .spacer-block {
            width: 100%;
            height: 5vh;
          }

          .floating-side {
            display:none
          }
        `}} >
      </style>
    </>
  );
};

export default Login;
