import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import { Accordion } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import classes from '../../css/item.module.css';
import Tilt from 'react-parallax-tilt';
import { Link } from 'react-router-dom';


const U_CaseView = (props) => {
    const useItems = [
        {
            title: "Free Speech",
            description: "Freely communicate through the blockchain without censorship.",
        },
        {
            title: "Secure and Easier Transactions",
            description: "Transfer item id's and other data alongside token transfers, simplifying Dapp development.",
        },
        {
            title: "No Smart Contracts",
            description: "Store and manage data in a decentralized manner without managing smart contracts.",
        },
        {
            title: "Achieve Historic and Research",
            description: "Store historical and new research publishing's allowing immutability, preservation, and anonymity.",
        },
        {
            title: "Decentralized Agreement Contracts",
            description: "Store public agreements with other decentralized parties, garnering a reputation while keeping anonymity.",
        },
        {
            title: "Moderation of Decentralized Content",
            description: "Services can contribute and read specific data from the chain for moderation purposes.",
        }
    ]


    return (
        <>
            <div className='user-item-container'>
                <div style={{ margin: 'auto', marginBottom: '50px' }} className='home-text-wrapper'>
                    <h1>
                        Use Cases For <span>Pluri</span>
                    </h1>
                    <div className='hero-center'>
                        <p style={{ maxWidth: '477px', color: 'white' }}>Explore how Pluri can be used in your projects and how liberating decentralized blockchain technology can be.</p>
                    </div>




                </div>

                <div className='user-items'>

                    <ol>
                        {useItems.filter((item, index) => index < 6).map((item) => {
                            return (
                                <li>
                                    <h4><span>{useItems.indexOf(item) + 1}</span>{item.title}</h4>
                                    <p>{item.description}</p>
                                </li>
                            )
                        })}
                    </ol>
                </div>

            </div>


        </>
    );
};

export default U_CaseView;
