import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import { useSelector } from 'react-redux'



const SectionCards = () => {
    const navigate = useNavigate();
    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);
    return (
        <>
            <div className='flex-card-container'>

                <Link to={
                    '/' + currentSelectedChain.slug + '/stakeholders'
                }>
                    <div data-aos="fade-up" data-aos-delay="100">

                        <div className='flex-card'>
                            <div className='flex-text'>
                                <i className='material-icons-rounded'>handshake</i>
                                <h2>Staking</h2>
                                <p>
                                    Staking lets you earn newly minted Pluri passively by locking 21,000 of your Pluri for 30 days, providing a steady stream of new Pluri without effort.
                                </p>
                            </div>
                            <div className='flex-button-wrapper'>

                                <button className='flex-button'>
                                    <span>Get Started</span>
                                    <i className='material-icons-rounded'>arrow_forward</i>
                                </button>

                            </div>

                        </div>

                    </div>
                </Link>

                <Link to={
                    '/' + currentSelectedChain.slug + '/faucet'
                }>
                    <div data-aos="fade-up" data-aos-delay="200">

                        <div className='flex-card'>
                            <div className='flex-text'>
                                <i className='material-icons-rounded'>toll</i>
                                <h2>Faucet</h2>
                                <p>
                                    Pluri's faucet offers a unique claim every 60 seconds that accumulates if not claimed. Each claim is available to the first person who redeems it, awarding them with newly minted Pluri.
                                </p>
                            </div>
                            <div className='flex-button-wrapper'>

                                <button className='flex-button'>
                                    <span>Get Started</span>
                                    <i className='material-icons-rounded'>arrow_forward</i>
                                </button>

                            </div>
                        </div>

                    </div>
                </Link>


            </div>

        </>
    );
};

export default SectionCards;
