import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { NavLink } from '../../node_modules/react-router-dom/dist/index';
import { useLocation } from '../../node_modules/react-router-dom/dist/index';


const Footer = (props) => {
    const location = useLocation(); // Added useLocation hook
    const currentSelectedChain = useSelector(state => state.mainapp.chainSlice.currentChain);
    const coin = useSelector(state => state.mainapp.chainSlice.currentChain.scan.coin);
    const faucet = useSelector(state => state.mainapp.chainSlice.currentChain.scan.faucet);
    const stakeholder = useSelector(state => state.mainapp.chainSlice.currentChain.scan.stakeholder);


    return (

        <>
            <div className='vivify fadeIn ' style={
                // check the current route if it includes /trydemo
                location.pathname.includes('demo/post') || location.pathname.includes('/articles/post/') ? { display: 'none' } : null
            }>
                <div style={
                    {
                        backgroundImage: `url("/assets/frostedFooter.svg")`,
                    }
                } className='footer  '>
                    <div className='footer-inner'>
                        <img src='/assets/plurilogo.png' alt='logo'></img>

                        <div className='footer-block'>
                            <h3>Explore</h3>

                            <Link to={currentSelectedChain.slug + '/user_console'}>Create A Post</Link>

                            <Link to={currentSelectedChain.slug + '/faucet'}>Get Pluri (Faucet)</Link>

                            <Link to={currentSelectedChain.slug + '/tokenomics'}>Tokenomics</Link>
                        </div>

                        <div className='footer-block'>
                            <h3>Developer</h3>
                            <Link to='https://pluri.gitbook.io/' target="_blank">Documentation</Link>
                            <Link to='https://pluri.gitbook.io/untitled'>
                                Read Data from Pluri
                            </Link>
                            <Link to='https://pluri.gitbook.io/untitled/pluri-methods/transferwithdata'>
                                Write Data to Pluri
                            </Link>

                        </div>

                        <div className='footer-block'>
                            <h3>Stakeholder</h3>
                            <Link to={currentSelectedChain.slug + '/faucet'}>Join Today</Link>
                            <Link to={currentSelectedChain.slug + '/tokenomics'}>Tokenomics</Link>
                            <Link to={currentSelectedChain.slug + '/trydemo'}>Try Demo</Link>
                        </div>

                        <div className='footer-block'>
                            <h3>Pluri {
                                currentSelectedChain.name
                            }</h3>
                            <Link to={
                                coin.url
                            } >
                                {coin.name} Token <i className='material-icons-rounded'>open_in_new</i>
                            </Link>

                            <Link to={
                                stakeholder.url
                            } >
                                {stakeholder.name} <i className='material-icons-rounded'>open_in_new</i>
                            </Link>

                            <Link to={
                                faucet.url
                            } >
                                {faucet.name} <i className='material-icons-rounded'>open_in_new</i>
                            </Link>


                        </div>



                    </div>


                </div>
                <div className='footer-bottom  vivify fadeIn duration-300 delay-200'>
                    <p>© 2024 Pluri. All rights reserved.</p>

                </div>
            </div>
        </>

    );

};

export default Footer;
